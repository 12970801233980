<template>
  <div class="payzilla">
    <!-- 顶部title -->
    <div class="top-img">
      <div class="top-center">
        <div class="top-title">
          <div>Buy & Sell <span class="title-span">crypto</span> with ease</div>
        </div>
        <div class="top-text">A fast way to enter crypto economy</div>
        <div class="top-btn" @click="handleLogin">Get Started</div>
      </div>
    </div>
    <div class="content">
      <div class="content-payzilla">
        <div class="payzilla-left color-051513">
          <div class="payzilla-title">
            A one-stop solution for trading digital currency
          </div>
          <div class="payzilla-text">
            Payzilla provides you fiat to crypto and crypto to fiat exchange
          </div>
        </div>
        <div class="payzilla-img">
          <img src="../../assets/image/logo1.png" alt="" />
        </div>
      </div>
      <div class="content-center">
        <div
          class="center-flex color-051513"
          v-for="(item, index) in cardList"
          :key="index"
        >
          <img class="center-img" :class="item.class" :src="item.img" alt="" />
          <div class="center-title">{{ item.title }}</div>
          <div class="center-text">{{ item.text }}</div>
        </div>
      </div>
    </div>
    <!-- 轮播 -->
    <div class="swiper-bg">
      <div class="swiper-title">Our Key Partners</div>
      <div class="swiperBox">
        <div class="swiperBox-left" @click="currentIndexPrev()">
          <img src="../../assets/image/icon14.png" alt="" />
        </div>
        <el-carousel
          v-if="width == 1"
          class="swiperBox-box"
          ref="swiperRef"
          :autoplay="true"
          arrow="never"
          indicator-position="none"
          :loop="true"
        >
          <el-carousel-item>
            <div class="swiperBox-card">
              <div class="swiperBox-logo">
                <img src="../../assets/image/logo3.png" alt="" />
              </div>
              <div class="swiperBox-logo swiperBox-logo4">
                <img src="../../assets/image/logo4.png" alt="" />
              </div>
              <div class="swiperBox-logo">
                <img class="" src="../../assets/image/logo5.png" alt="" />
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="swiperBox-card">
              <div class="swiperBox-logo">
                <img src="../../assets/image/logo3.png" alt="" />
              </div>
              <div class="swiperBox-logo swiperBox-logo4">
                <img src="../../assets/image/logo4.png" alt="" />
              </div>
              <div class="swiperBox-logo">
                <img class="" src="../../assets/image/logo5.png" alt="" />
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
        <el-carousel
          v-if="width == 2"
          class="swiperBox-H5"
          ref="swiperRef"
          :autoplay="true"
          arrow="never"
          indicator-position="none"
          :loop="true"
        >
          <el-carousel-item class="carousel-item">
            <div class="swiperBox-logo">
              <img src="../../assets/image/logo3.png" alt="" />
            </div>
          </el-carousel-item>
          <el-carousel-item class="carousel-item">
            <div class="swiperBox-logo swiperBox-logo4">
              <img src="../../assets/image/logo4.png" alt="" />
            </div>
          </el-carousel-item>
          <el-carousel-item class="carousel-item">
            <div class="swiperBox-logo">
              <img src="../../assets/image/logo5.png" alt="" />
            </div>
          </el-carousel-item>
        </el-carousel>
        <div class="swiperBox-right" @click="currentIndexNext">
          <img src="../../assets/image/icon15.png" alt="" />
        </div>
      </div>

      <!-- 黑白card -->
      <div class="card">
        <div class="card-top color-051513">
          <div class="card-title">Payzilla Accounts</div>
          <div class="card-text">
            Designed to enable access to digital assets for both businesses and
            individuals
          </div>
        </div>
        <div class="card-center">
          <div class="center-left">
            <div class="left-logo">
              <img src="../../assets/image/logo2.png" alt="" />
            </div>
            <div class="left-title">
              Personal <span class="color-00A965">Account</span>
            </div>
            <div
              class="left-text color-051513"
              v-for="(item, index) in cardLeftList"
              :key="index"
            >
              <div class="text-top">{{ item.topText }}</div>
              <div class="text-bottom">{{ item.bottomText }}</div>
            </div>
          </div>
          <div class="center-left right-bg">
            <div class="left-logo">
              <img src="../../assets/image/logo.png" alt="" />
            </div>
            <div class="left-title">
              Business <span class="color-00A965">Account</span>
            </div>
            <div
              class="left-text"
              v-for="(item, index) in cardRightList"
              :key="index"
            >
              <div class="text-top">{{ item.topText }}</div>
              <div class="text-bottom">{{ item.bottomText }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="bottom-top">
      <div class="top-title">Ready to get started?</div>
      <div class="top-center">
        Enable trading between fiat currency and cryptocurrency.
      </div>
      <div class="top-btn" @click="handleLogin">Get Started</div>
    </div>
    <div class="top-bottom"></div>

    <el-dialog
      class="dialog-web"
      title="Tips"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <span class="dialog-text"
        >drop us an enquiry email to: support@payzilla.io</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button
          class="dialog-btn"
          type="primary"
          @click="dialogVisible = false"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
    <!-- 弹框 -->
    <div class="bottom-dialog" v-if="dialogVisibleH5">
      <div class="dialog-top">
        <div>Tips</div>
        <div @click="dialogVisibleH5 = false">X</div>
      </div>
      <div class="dialog-center">
        drop us an enquiry email to: support@payzilla.io
      </div>
      <div class="dialog-bottom-btn">
        <div @click="dialogVisibleH5 = false" class="btn">Confirm</div>
      </div>
    </div>
  </div>
</template>

<script>
import {isMobile} from '@/utils/index.js'
export default {
  name: "index",
  data() {
    return {
      width: 2, // 1 web 2 H5
      currentIndex: 0,
      supLogo1: false,
      supLogo2: false,
      supLogo3: false,
      supLogo4: false,
      dialogVisible: false,
      dialogVisibleH5: false,
      cardList: [
        {
          img: require("../../assets/image/icon4.png"),
          title: "Easy to use",
          text: "Our easy-to-use UI guides you thought the buy & sell journey",
          class: "center-icon4",
        },
        {
          img: require("../../assets/image/icon6.png"),
          title: "Multiple payment options",
          text: "We accept major payment options, including VISA / Mastercard, Goolge pay, Apple pay, SEPA, bank transfers.",
          class: "center-icon6",
        },
        {
          img: require("../../assets/image/icon5.png"),
          title: "Compliance and security",
          text: "KYC process done right to ensure minimal drop offs while protecting your risks with our anti-fraud capability.",
          class: "center-icon5",
        },
        {
          img: require("../../assets/image/icon4.png"),
          title: "Custom support",
          text: "Our professional custom support makes your on/off ramping experience smooth.",
          class: "center-icon4",
        },
      ],
      cardLeftList: [
        {
          topText: "Instant Access to a Web3-driven Lifestyle",
          bottomText:
            "Payzilla service caters for a wide range of Web3 clients: CEX/DEX, GameFi, SocialFi, Crypto Wallets, Web3 Projects, and more.",
        },
        {
          topText: "Effortless KYC",
          bottomText:
            "A user-friendly KYC process that gets people onboard with minimal friction.",
        },
      ],
      cardRightList: [
        {
          topText: "Accelerate Your Digital Growth",
          bottomText:
            "Bridge your business need between Web3 and Web2.  Swift, Safe and Easy",
        },
        {
          topText: "Beyond Boundaries",
          bottomText:
            "Payzilla provides you a global financial connectivity with  seamless cross-border operations.",
        },
      ],
    };
  },
  created() {
    // supRefs
    // console.log(this.$refs.supRefs.getBoundingClientRect().top)
  },
  mounted() {
    //获取页面宽度
    let userAgent = navigator.userAgent.toLowerCase();
    if (
      userAgent.indexOf("mobile") !== -1 ||
      userAgent.indexOf("android") !== -1 ||
      userAgent.indexOf("iphone") !== -1 ||
      userAgent.indexOf("ipad") !== -1 ||
      userAgent.indexOf("windows phone") !== -1
    ) {
      this.width = 2;
    }
    this.checkMediaQuery();
    window.addEventListener("resize", this.checkMediaQuery);
  },
  methods: {
	handleLogin(){
		if(isMobile()){
			return
		}
	  	window.open('http://otctrade.payzilla.io','_bank')
	  },
    handleType() {
      this.showIcon = true;
      this.showMenu = false;
      this.showSupportIcon = true;
    },
    handleAbout() {
      this.handleType();
      this.$router.push({
        path: "/about",
      });
    },
    handleAccounts() {
      let scrollPosition = this.calculateScrollPosition(".card");
      scrollPosition = scrollPosition + 80;
      this.handleScrollTo(scrollPosition);
    },
    //滚动到指定位置
    scrollToPosition() {
      let scrollPosition = this.calculateScrollPosition(".content");
      scrollPosition = scrollPosition - 80;
      this.handleScrollTo(scrollPosition);
    },
    // 动态计算滚动位置
    calculateScrollPosition(className) {
      let contentElement = document.querySelector(className);
      let contentTop =
        contentElement.getBoundingClientRect().top + window.pageYOffset;
      // 根据屏幕宽度调整滚动位置
      let scrollPosition = contentTop;
      return scrollPosition;
    },
    // 页面滚动
    handleScrollTo(position) {
      window.scrollTo({
        top: position,
        behavior: "smooth",
      });
    },
    //监听页面宽度变化
    checkMediaQuery() {
      if (/Android|iPhone|iPad|iPod/i.test(navigator.platform)) {
        this.width = 2;
      } else {
        const mediaQueryList = window.matchMedia("(max-width: 768px)");
        if (mediaQueryList.matches) {
          if (this.width == 1) this.width = 2;
        } else {
          if (this.width == 2) this.width = 1;
        }
      }
    },

    currentIndexPrev() {
      this.$refs.swiperRef.prev();
    },
    currentIndexNext() {
      this.$refs.swiperRef.next();
    },
    handleRamps() {
      // window.location.href = "https://example.com";
    },
    handlePDF(url) {
      if (url) window.open(url);
    },
    handleContact() {
      if (this.width == 1) this.dialogVisible = true;
      else this.dialogVisibleH5 = true;
    },
  },
};
</script>

<style lang="scss" scoped>
//.animateFadeOut{
//  animation: fadeOut;
//}
//@keyframes fadeOut {
//  from {
//    opacity: 1;
//  }
//
//  to {
//    opacity: 0;
//  }
//}
.payzilla {
  font-family: "font1", sans-serif;
  -webkit-text-size-adjust: 100% !important; /* 或者使用 'none' */
}
.swiper-wrapper {
  transition-timing-function: linear !important; //想好具体位置放到哪，得能替换！
}

@import "../../css/public.scss";

.color-051513 {
  color: #051513;
}

.color-00A965 {
  color: #00a965;
}

.top-img {
  padding-top: 200px;
  background-image: url("../../assets/image/image1.png");
  background-repeat: no-repeat;
  background-size: 120% 110%;
  background-position: -200px 50px;
  padding-bottom: 550px;

  // font-family: Source Sans Pro, sans-serif;
  .top-center {
    margin-left: 50%;
    transform: translateX(-50%);

    .top-title {
      text-align: left;
      font-size: 70px;
      color: #fff;
      white-space: nowrap;
      // // font-weight: 700;;
      font-family: "font2", sans-serif !important;

      .title-span {
        color: #00d37e;
      }
    }

    .top-text {
      width: 500px;
      font-size: 22px;
      color: #fff;
      margin-top: 30px;
      line-height: 32px;
    }

    .top-btn {
      width: 155px;
      height: 48px;
      padding: 4px 15px;
      background-color: #00a965;
      color: #fff;
      line-height: 48px;
      text-align: center;
      border-radius: 5px;
      margin-top: 25px;
      cursor: pointer;
      transition: transform 0.3s ease-in-out;
      font-size: 20px;
      font-family: "font2" !important;
    }

    .top-btn:hover {
      transform: translateY(-5px);
      /* 鼠标悬浮时向上移动10px */
    }
  }
}

.content {
  max-width: 1380px;
  margin: auto;

  .content-payzilla {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .payzilla-left {
      display: flex;
      flex-direction: column;

      .payzilla-title {
        font-size: 44px;
        font-family: "font2" !important;
        max-width: 450px;
        line-height: 50px;
      }

      .payzilla-text {
        width: 400px;
        margin-top: 20px;
        font-size: 22px;
        // font-weight: 400;;
        line-height: 26px;
      }
    }

    .payzilla-img {
      width: 720px;
      height: 300px;
    }
  }

  .content-center {
    display: flex;
    justify-content: space-between;
    margin-top: 100px;

    .center-flex {
      display: flex;
      flex-direction: column;
      width: 300px;
      background: #fff;

      .center-img {
        width: 65px;
        height: 60px;
      }

      .center-icon4 {
      }

      .center-icon5 {
        width: 52px !important;
        height: 60px !important;
      }

      .center-icon6 {
        width: 55px !important;
        height: 60px !important;
      }

      .center-title {
        margin-top: 20px;
        font-size: 24px;
        // // font-weight: 700;;
        line-height: 28px;
        min-height: 30px;
        font-family: "font2" !important;
      }

      .center-text {
        font-size: 18px;
        line-height: 20px;
        margin-top: 20px;
        // font-weight: 500;
      }
    }
  }
}

.swiper-title {
  text-align: center;
  font-size: 44px;
  // // font-weight: 700;;
  margin-top: 115px;
  font-family: "font2", sans-serif !important;
}

.swiperBox {
  margin-top: 40px;
  display: flex;
  justify-content: center;

  .swiperBox-left {
    margin-right: 50px;
    height: 130px;
    display: flex;
    align-items: center;

    img {
      width: 45px;
      height: 45px;
    }
  }

  .swiperBox-right {
    height: 130px;
    display: flex;
    align-items: center;
    margin-left: 50px;

    img {
      width: 45px;
      height: 45px;
    }
  }

  .swiperBox-box {
    width: 1150px;

    .swiperBox-card {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .swiperBox-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 350px;
        height: 130px;
        background-image: url("../../assets/image/image5.png");
        background-position: 100%;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        box-shadow: 0 4px 8px #1b27361a;

        img {
          width: 200px;
          height: 50px;
        }
      }

      .swiperBox-logo4 {
        img {
          width: 250px;
          height: 38px;
        }
      }
    }
  }
}

.card {
  padding-top: 250px;
  background-image: url("../../assets/image/image2.png");
  background-position: 0px -10px;
  background-size: 100% 110%;
  background-repeat: no-repeat;

  .card-top {
    width: 100%;
    text-align: center;

    .card-title {
      font-size: 42px;
      // font-weight: 700;;
      margin-bottom: 20px;
      font-family: "font2" !important;
    }

    .card-text {
      color: #777777;
      font-size: 20px;
      margin-bottom: 20px;
      font-family: "font2-Semibold" !important;
    }
  }

  .card-center {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    padding-bottom: 50px;

    .right-bg {
      background-color: #051513 !important;
      color: #fff;
      margin-left: 30px;
    }

    .center-left {
      width: 530px;
      padding: 100px 40px;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 1px 1px 8px #00d37e33;
      text-align: center;

      .left-logo {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;

        img {
          width: 180px;
          height: 35px;
        }
      }

      .left-title {
        font-size: 32px;
        // font-weight: 700;;
        font-family: "font2", sans-serif !important;
      }

      .left-text {
        text-align: left;
        margin-top: 50px;

        .text-top {
          font-size: 24px;
          // font-weight: 700;;
          line-height: 30px;
          font-family: "font2" !important;
        }

        .text-bottom {
          margin-top: 15px;
          font-size: 18px;
          line-height: 22px;
        }
      }
    }
  }
}

.bottom-top {
  background-color: #051513;
  color: #fff;
  padding: 100px 0;
  padding-bottom: 48px;
  margin-top: 200px;
  text-align: center;

  .top-title {
    font-size: 36px;
    // font-weight: 700;;
    line-height: 50px;
    font-family: "font2", sans-serif !important;
  }

  .top-center {
    font-size: 18px;
    line-height: 24px;
    margin-top: 24px;
    font-weight: 300;
    font-family: "font2-Light", sans-serif !important;
  }

  .top-btn {
    margin-left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    margin-top: 44px;
    width: 155px;
    height: 48px;
    line-height: 48px;
    border-radius: 5px;
    background-color: #00a965;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    font-size: 20px;
    padding: 4px 15px;
    font-family: "font2", sans-serif !important;
  }

  .top-btn:hover {
    transform: translate(-50%, -5px);
    /* 鼠标悬浮时向上移动10px */
  }
}

.top-bottom {
  background-color: #00d37e;
  width: 100%;
  height: 3px;
}

.bottom-btm {
  display: flex;
  justify-content: center;
  margin-top: 50px;

  .btm-top {
    width: 1280px;
    display: flex;
    justify-content: space-between;
    color: #fff;
    // font-weight: 500;

    .btm-top-left {
      width: 165px;
      height: 32px;
    }

    .right-content {
      font-size: 18px;
      cursor: pointer;

      .content-flex div:hover {
        color: #00d37e;
      }

      .right-content-icon {
        display: flex;

        img {
          width: 18px;
          height: 18px;
          margin-right: 15px;
        }
      }

      div {
        margin-bottom: 20px;
        // font-weight: 700;;
        font-family: "font2", sans-serif !important;
      }

      & > :nth-child(1) {
        color: #adadad !important;
        font-size: 12px !important;
        margin-bottom: 25px !important;
      }
    }
  }
}

.fw-400 {
  width: 100%;
  margin: auto;
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
  text-align: center;

  span {
    display: inline-block;
    width: 600px;
  }
}

.btm-font-text {
  width: 920px;
  margin: auto;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  opacity: 0.7;
  text-align: center;
  font-family: "font1-Light";
}

.mt-200 {
  margin-top: 80px;
}

.mt-25 {
  margin-top: 25px;
}

@media screen and (max-device-width: 768px) {
  .top-img {
    padding-top: 300px;
    background-size: 117% 60%;
    background-position: -30px 1000px;
    padding-bottom: 1200px;

    .top-center {
      padding-top: 180px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 0;
      transform: translateX(-0%);
      background-color: #051513;

      .top-title {
        width: 1000px;
        text-align: center;
        font-size: 140px;
        color: #fff;
        white-space: pre-wrap;
        line-height: 180px;

        .title-span {
          color: #00d37e;
        }
      }

      .top-text {
        width: 1300px;
        font-size: 72px;
        line-height: 100px;
        text-align: center;
        color: #fff;
        line-height: 180px;
        margin-top: 30px;
        opacity: 0.9;
      }

      .top-btn {
        width: 700px;
        height: 230px;
        background-color: #00a965;
        color: #fff;
        line-height: 230px;
        text-align: center;
        border-radius: 25px;
        margin-top: 120px;
        font-size: 80px;
      }
    }
  }

  .content {
    max-width: 100%;
    margin: auto;
    margin-top: 200px;

    .content-payzilla {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;

      .payzilla-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 85%;
        padding-top: 600px;

        .payzilla-title {
          font-size: 165px;
          // // font-weight: 700;;
          max-width: 100%;
          line-height: 160px;
          font-family: "font2" !important;
        }

        .payzilla-text {
          width: 80%;
          margin-top: 50px;
          font-size: 85px;
          line-height: 120px;
          color: #051513;
        }
      }

      .payzilla-img {
        width: 100%;
        height: 800px;
        margin: 200px 0;
      }
    }

    .content-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 300px;
      width: 100%;

      .center-flex {
        display: flex;
        flex-direction: column;
        width: 80%;
        background: #fff;
        margin-bottom: 260px;

        .center-img {
          width: 320px;
          height: 290px;
        }

        .center-icon4 {
        }

        .center-icon5 {
          width: 250px !important;
          height: 290px !important;
        }

        .center-icon6 {
          width: 280px !important;
          height: 290px !important;
        }

        .center-title {
          margin-top: 100px;
          font-size: 110px;
          line-height: 120px;
          margin-bottom: 40px;
        }

        .center-text {
          font-size: 80px;
          line-height: 120px;
        }
      }
    }
  }

  .swiper-bg {
    background-image: url("../../assets/image/image3.png");
    background-position: 0px 0px;
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .swiper-title {
      text-align: center;
      font-size: 175px;
      //  font-weight: 700;;
      margin-bottom: 250px;
      margin-top: 450px;
      padding-top: 270px;
    }

    .swiperBox {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      .swiperBox-left {
        margin-right: 60px;
        height: 260px;
        display: flex;
        align-items: center;

        img {
          width: 185px;
          height: 185px;
        }
      }

      .swiperBox-right {
        height: 400px;
        display: flex;
        align-items: center;
        margin-left: 60px;

        img {
          width: 185px;
          height: 185px;
        }
      }

      .swiperBox-H5 {
        width: 1200px;
        height: 800px;
        box-shadow: 0 8px 16px #1b27361a;
        background-image: url("../../assets/image/image5.png");
        background-position: 100%;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        border-radius: 50px;

        .carousel-item {
          display: flex;
          justify-content: center;
          align-items: center;

          box-shadow: 0 8px 16px #1b27361a;

          .swiperBox-logo {
            img {
              width: 750px;
              height: 190px;
            }
          }

          .swiperBox-logo4 {
            img {
              width: 850px !important;
              height: 140px !important;
            }
          }
        }
      }
    }
  }

  ::v-deep .el-carousel__container {
    width: 100%;
    height: 100%;
  }

  .card {
    background-image: none;
    padding-top: 450px;

    .card-top {
      width: 95%;
      margin: auto;

      .card-title {
        font-size: 165px;
        margin-bottom: 100px;
      }

      .card-text {
        padding: 0 80px;
        color: #777777;
        font-size: 84px;
        margin-bottom: 20px;
        font-family: "font2-Semibold" !important;
      }
    }

    .card-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 350px;
      background-image: url("../../assets/image/image6.png");
      background-position: 0px 0px;
      background-size: 100% 120%;
      background-repeat: no-repeat;
      padding-bottom: 200px;

      .right-bg {
        background-color: #051513 !important;
        color: #fff;
        margin-top: 100px;
      }

      .center-left {
        width: 80%;
        padding: 300px 160px;
        border-radius: 25px;
        background-color: #fff;
        box-shadow: 8px 8px 50px #00d37e33;
        text-align: center;
        box-sizing: border-box;

        .left-logo {
          display: flex;
          justify-content: center;
          margin-bottom: 80px;

          img {
            width: 550px;
            height: 120px;
          }
        }

        .left-title {
          font-size: 135px;
          // font-weight: 700;;
        }

        .left-text {
          text-align: left;
          margin-top: 200px;

          .text-top {
            font-size: 110px;
            // font-weight: 700;;
            line-height: 130px;
          }

          .text-bottom {
            margin-top: 30px;
            font-size: 80px;
            // font-weight: 400;;
            line-height: 110px;
          }
        }
      }
    }
  }

  .bottom-top {
    padding: 1300px 0;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    margin-top: 200px;
    .top-title {
      font-size: 165px;
      // // font-weight: 700;;
      line-height: 180px;
    }

    .top-center {
      width: 75%;
      font-size: 95px;
      // font-weight: 400;;
      line-height: 120px;
      margin-top: 60px;
    }

    .top-btn {
      margin-left: 0%;
      transform: translateX(0%);
      margin-top: 180px;
      width: 700px;
      height: 230px;
      line-height: 230px;
      border-radius: 25px;
      background-color: #00a965;
      font-size: 85px;
      // // font-weight: 700;;
    }
    .top-btn:hover {
      transform: translate(0%, 0%);
      /* 鼠标悬浮时向上移动10px */
    }
  }

  .top-bottom {
    background-color: #00a965;
    width: 100%;
    height: 14px;
  }

  .bottom-btm {
    display: flex;
    justify-content: center;
    margin-top: 500px;

    .btm-top {
      width: 80%;
      display: flex;
      flex-direction: column;

      color: #fff;
      // font-weight: 500;

      .h5-none {
        display: none;
      }

      .btm-top-left {
        width: 550px;
        height: 120px;
      }

      .right-content {
        margin-top: 200px;
        font-size: 55px;

        .content-flex {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          max-height: 1200px;
          font-size: 80px !important;
          margin-bottom: 0 !important;
        }

        .right-content-icon {
          display: flex;

          img {
            width: 90px;
            height: 90px;
            margin-right: 100px;
          }
        }

        div {
          margin-bottom: 70px;
        }

        & > :nth-child(1) {
          color: #adadad !important;
          font-size: 65px !important;
          padding-bottom: 100px !important;
        }
      }
    }
  }

  .fw-400 {
    font-size: 90px;
    // font-weight: 400;;
    line-height: 120px;
    text-align: center;
    font-family: "font1-Light";

    span {
      display: inline-block;
      width: 83%;
    }
  }

  .btm-font-text {
    width: 83%;
    margin: auto;
    // font-weight: 400;;
    font-size: 65px;
    line-height: 100px;
    text-align: center;
    font-family: "font1-Light" !important;
  }

  .mt-200 {
    margin-top: 400px;
  }

  .mt-25 {
    margin-top: 200px;
  }

  .mt-100 {
    margin-top: 100px;
  }
}

.bottom-dialog {
  width: 75vw;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 100px 80px;
  color: #051513;
  z-index: 9999;

  .dialog-top {
    display: flex;
    justify-content: space-between;
    font-size: 70px;
    // font-weight: 400;;
    font-family: "font1-Light" !important;
    opacity: 0.7;
  }

  .dialog-center {
    font-size: 70px;
    // font-weight: 400;;
    margin: 150px 0;
    font-family: "font1-Light" !important;
    opacity: 0.7;
  }

  .dialog-bottom-btn {
    display: flex;
    flex-direction: row-reverse;

    div {
      width: 400px;
      font-size: 85px;
      // // font-weight: 700;;
      text-align: center;
      border-radius: 15px;
      color: #fff;
      padding: 30px 50px;
      background-color: #00a965;
    }
  }
}

::v-deep .dialog-web .el-dialog__title {
  color: #051513;
  // font-weight: 400;;
  font-size: 18px;
  line-height: 18px;
}

::v-deep .dialog-web .el-dialog__headerbtn .el-dialog__close {
  color: #051513;
  /* 修改关闭图标的颜色 */
}

.dialog-btn {
  width: 116px;
  height: 48px;
  border-radius: 5px;
  padding: 0;
  background-color: #00a965;
  border-color: #00a965;
  font-size: 18px;
  // // font-weight: 700;;
  font-family: "font2";
}

.dialog-text {
  // font-weight: 400;;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  font-family: "font1-Light";
  color: #051513;
}
</style>
